import * as React from "react";
import { graphql } from "gatsby";

// Layout
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { buildFluidImageData } from "@imgix/gatsby";

// Custom Components
import PageBanner from "../../components/PageBanner";
import SeasonalHours from "../../components/Matrix/types/Independent/SeasonalHours";

// Tailwind React UI Components
import { Container, Title, Section, Text } from "tailwind-react-ui";

// GraphQL
export const query = graphql`
  query OperatingHoursPageQuery {
    entry: craftPlanYourVisitPlanYourVisitEntry {
      id
      uri
      url
      title
      subtitle
      summary
      featureImage {
        ...UploadAssetFragment
      }
      displayTitle
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      journey {
        ... on Craft_journey_step_BlockType {
          id
          heading
          image {
            ...UploadAssetFragment
          }
          icon {
            url
          }
          iconBackgroundColour
          text
        }
      }
      introductionRow {
        ...IntroductionRow
      }
      applicableDates
      applicableDatesNew
      bodyContent {
        ...BodyContentFragment
      }
      infoRow {
        ...InfoRowFragment
      }
      infoRowNew {
        ...InfoRowNewFragment
      }
      additionalText
      seasonalHours {
        ...SeasonalHoursFragment
      }
      yellowContentSlider {
        ... on Craft_yellowContentSlider_slide_BlockType {
          heading
          image {
            ...UploadAssetFragment
          }
          text
          buttonText
          buttonUrl
          relatedEntry {
            uri
          }
        }
      }
      quickTips {
        ...QuickTipsFragment
      }
      heading {
        ... on Craft_heading_heading_BlockType {
          id
          text
        }
      }
      accordion {
        ... on Craft_accordion_accordionRow_BlockType {
          id
          heading
          text
        }
      }
      imageOnly {
        ...UploadAssetFragment
      }
      richText
      buttons {
        ...ButtonsFragment
      }
      signUpBanner {
        ...SignUpBannerFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

// {data.site.title}
const OperatingHours = ({ data: { entry, siteInfo } }) => {
  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      <Section p="0" className="p-0 relative">
        <PageBanner
          title={"Operating Hours"}
          subtitle={subtitle}
          image={featureImage}
        />
      </Section>

      <Section
        id="hours"
        bg="blue-dark"
        text="white"
        className="relative"
        p="0"
      >
        <Container className="py-14 lg:py-20 w-full xl:w-3/4">
          <Title
            text="secondary"
            level={3}
            font="heading"
            className="text-secondary font-heading"
          >
            Operating Hours
          </Title>
          <Text is="p" className="pb-6">
            The cable car operates - weather permitting - during the following
            times:
          </Text>
          <SeasonalHours block={entry.seasonalHours} />
        </Container>
      </Section>
    </Layout>
  );
};

export default OperatingHours;
