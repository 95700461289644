import React from "react"

// Tailwind React UI
import { 
    Box,
    Flex,
    Text,
    Title
} from 'tailwind-react-ui'

const SeasonalHours = ({ block }) => {

    return (
        <Box p="0" className="my-0 lg:my-6" data-component-type="seasonal-hours">
            <div className="accordion">
                {block.map((tab, index) => ( 
                    <Box key={`season${index}`} className="tab mb-2">
                        <input type="checkbox" id={`tab${index}`} />
                        <label className="tab-label py-2  px-3 lg:px-6 bg-white border border-white mb-0" htmlFor={`tab${index}`}>
                            <Flex items="center" className="lg:py-2">
                                <Box className="my-auto">
                                    <svg className="arrow" xmlns="https://www.w3.org/TR/SVG" width="13.084" height="21.214" viewBox="0 0 13.084 21.214">
                                    <path id="Path_404" data-name="Path 404" d="M0,18.03,1.77,19.8l9.9-9.9L1.77,0,0,1.77,8.13,9.9,0,18.03Z" transform="translate(0.707 0.707)" fill="#232E4E" stroke="#232E4E" strokeWidth="1"/>
                                    </svg>
                                </Box>
                                <Box className="px-5 my-auto">
                                    <img src={tab.icon[0].url} loading="lazy" className="tab-icon" alt={tab.heading} />
                                </Box>
                                <Box className="my-auto">
                                    <Title level={4} font="heading" m="0" className="font-heading mx-0 mb-0 mt-1 text-primary text-md md:text-2xl lg:text-3xl">{tab.heading}</Title>
                                </Box>
                            </Flex>
                            
                        </label>
                        <div className="tab-content pb-0">
                            {/* General Cableway hours */}
                            {block[index].hours ? <><Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">First <span className="hidden md:inline">car </span> up</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Last <span className="hidden md:inline">car </span> up</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Last <span className="hidden md:inline">car </span> down</Text>
                                </Box>
                            </Flex></> :''}
                            {block[index].hours && block[index].hours.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        {col.dates ? <Box className="p-3 lg:p-6 w-1/3 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates}</Text>
                                        </Box>: '' }
                                        {col.firstCarUp ? <Box className="p-3 lg:p-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.firstCarUp}</Text>
                                        </Box>: '' }
                                        {col.lastCarUp ? <Box className="p-3 lg:p-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.lastCarUp}</Text>
                                        </Box>: '' }
                                        {col.lastCarDown ? <Box className="p-3 lg:p-6 w-1/5 lg:w-1/4" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.lastCarDown}</Text>
                                        </Box>: '' }
                                    </Flex>   
                                </>
                            ))}

                            {/* Cafe hours */}
                            {block[index].cafeHours ? <><Title level={6} size={1} className={`mt-8 px-3 lg:px-6 mb-3 text-white flex items-baseline font-basic font-bold`}><svg xmlns="https://www.w3.org/TR/SVG" width="22.815" height="20" className="mb-1 mr-2" viewBox="0 0 22.815 20">
                                <path id="icon_food" data-name="icon/food" d="M23.485,8.163l-1.87,14.662H15.3L13.434,8.09h7.713l1.524-5.265.864.26L22.073,8.152l1.412.011ZM12.541,16.645s.236-1.9-3.04-1.9H4.284c-3.271,0-3.04,1.9-3.04,1.9Zm-11.3,4.278s-.231,1.9,3.04,1.9H9.5c3.276,0,3.04-1.9,3.04-1.9Zm10.806-.951a1.093,1.093,0,0,0,.969-1.188,1.092,1.092,0,0,0-.969-1.189H1.69a1.094,1.094,0,0,0-.97,1.189,1.1,1.1,0,0,0,.97,1.188H12.051Z" transform="translate(-0.72 -2.824)" fill="#6ea13e"/>
                                </svg><span className="text-green">Ten67</span>&nbsp;-&nbsp;<span>opens with first car up and closes 30 minutes before last car down</span></Title>
                            <Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Opens</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Closes</Text>
                                </Box>
                            </Flex></> :''}

                            {block[index].cafeHours && block[index].cafeHours.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates && col.dates}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.opening && col.opening}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.closing && col.closing}</Text>
                                        </Box>
                                    </Flex>   
                                </>    
                            ))}

                            {/* Lower kiosk hours 
                            {block[index].lowerKioskHours ? <><Title level={6} font="display" text="white" className={`font-display mt-8 px-3 lg:px-6 mb-3 text-white flex items-baseline font-bold`}><svg xmlns="https://www.w3.org/TR/SVG" width="22.815" height="20" className="mb-1 mr-2" viewBox="0 0 22.815 20">
                                <path id="icon_food" data-name="icon/food" d="M23.485,8.163l-1.87,14.662H15.3L13.434,8.09h7.713l1.524-5.265.864.26L22.073,8.152l1.412.011ZM12.541,16.645s.236-1.9-3.04-1.9H4.284c-3.271,0-3.04,1.9-3.04,1.9Zm-11.3,4.278s-.231,1.9,3.04,1.9H9.5c3.276,0,3.04-1.9,3.04-1.9Zm10.806-.951a1.093,1.093,0,0,0,.969-1.188,1.092,1.092,0,0,0-.969-1.189H1.69a1.094,1.094,0,0,0-.97,1.189,1.1,1.1,0,0,0,.97,1.188H12.051Z" transform="translate(-0.72 -2.824)" fill="#6ea13e"/>
                                </svg><span className="text-green">Lower kiosk</span>&nbsp;-&nbsp;<span>opens with first car up and closes 15 minutes before last car down</span></Title>
                            <Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Opens</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Closes</Text>
                                </Box>
                            </Flex></> : ''}
                            {block[index].lowerKioskHours && block[index].lowerKioskHours.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates && col.dates}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.opening && col.opening}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.closing && col.closing}</Text>
                                        </Box>
                                    </Flex>   
                                </>    
                            ))}*/}

                            {/* WiFi Lounge hours */}
                            {block[index].wiFiLounge ? <><Title level={6} font="display" text="white" className={`font-display mt-8  px-3 lg:px-6 mb-3 text-white flex items-baseline font-bold`}><svg xmlns="https://www.w3.org/TR/SVG" width="22.815" height="20" className="mb-1 mr-2" viewBox="0 0 22.815 20">
                                <path id="icon_food" data-name="icon/food" d="M23.485,8.163l-1.87,14.662H15.3L13.434,8.09h7.713l1.524-5.265.864.26L22.073,8.152l1.412.011ZM12.541,16.645s.236-1.9-3.04-1.9H4.284c-3.271,0-3.04,1.9-3.04,1.9Zm-11.3,4.278s-.231,1.9,3.04,1.9H9.5c3.276,0,3.04-1.9,3.04-1.9Zm10.806-.951a1.093,1.093,0,0,0,.969-1.188,1.092,1.092,0,0,0-.969-1.189H1.69a1.094,1.094,0,0,0-.97,1.189,1.1,1.1,0,0,0,.97,1.188H12.051Z" transform="translate(-0.72 -2.824)" fill="#6ea13e"/>
                                </svg><span className="text-green">VIEWS</span></Title>
                            <Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Opens</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Closes</Text>
                                </Box>
                            </Flex></> : ''}
                            {block[index].wiFiLounge && block[index].wiFiLounge.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates && col.dates}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.opening && col.opening}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.closing && col.closing}</Text>
                                        </Box>
                                    </Flex>   
                                </>    
                            ))}

                            {/* KLOUD hours */}
                            {block[index].kloudHours ? <><Title level={6} size={1} className={`mt-8 px-3 lg:px-6 mb-3 text-white flex items-baseline font-basic font-bold`}><svg xmlns="https://www.w3.org/TR/SVG" width="22.815" height="20" className="mb-1 mr-2" viewBox="0 0 22.815 20">
                                <path id="icon_food" data-name="icon/food" d="M23.485,8.163l-1.87,14.662H15.3L13.434,8.09h7.713l1.524-5.265.864.26L22.073,8.152l1.412.011ZM12.541,16.645s.236-1.9-3.04-1.9H4.284c-3.271,0-3.04,1.9-3.04,1.9Zm-11.3,4.278s-.231,1.9,3.04,1.9H9.5c3.276,0,3.04-1.9,3.04-1.9Zm10.806-.951a1.093,1.093,0,0,0,.969-1.188,1.092,1.092,0,0,0-.969-1.189H1.69a1.094,1.094,0,0,0-.97,1.189,1.1,1.1,0,0,0,.97,1.188H12.051Z" transform="translate(-0.72 -2.824)" fill="#6ea13e"/>
                                </svg><span className="text-green">KLOUD</span></Title>
                            <Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Opens</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Closes</Text>
                                </Box>
                            </Flex></> :''}
                            {block[index].kloudHours && block[index].kloudHours.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates && col.dates}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.opening && col.opening}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.closing && col.closing}</Text>
                                        </Box>
                                    </Flex>   
                                </>    
                            ))}

                            {/* TAP BAR hours */}
                            {block[index].tapBarHours ? <><Title level={6} size={1} className={`mt-8 px-3 lg:px-6 mb-3 text-white flex items-baseline font-basic font-bold`}><svg xmlns="https://www.w3.org/TR/SVG" width="22.815" height="20" className="mb-1 mr-2" viewBox="0 0 22.815 20">
                                <path id="icon_food" data-name="icon/food" d="M23.485,8.163l-1.87,14.662H15.3L13.434,8.09h7.713l1.524-5.265.864.26L22.073,8.152l1.412.011ZM12.541,16.645s.236-1.9-3.04-1.9H4.284c-3.271,0-3.04,1.9-3.04,1.9Zm-11.3,4.278s-.231,1.9,3.04,1.9H9.5c3.276,0,3.04-1.9,3.04-1.9Zm10.806-.951a1.093,1.093,0,0,0,.969-1.188,1.092,1.092,0,0,0-.969-1.189H1.69a1.094,1.094,0,0,0-.97,1.189,1.1,1.1,0,0,0,.97,1.188H12.051Z" transform="translate(-0.72 -2.824)" fill="#6ea13e"/>
                                </svg><span className="text-green">TAP BAR</span></Title>
                            <Flex align="start" wrap justify="between" text="white" className="my-3">
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Dates</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Opens</Text>
                                </Box>
                                <Box className="px-3 lg:px-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                    <Text color="yellow" className="font-basic font-bold">Closes</Text>
                                </Box>
                            </Flex></> :''}
                            {block[index].tapBarHours && block[index].tapBarHours.map((col, h) => (
                                <>    
                                    <Flex key={h} align="start" wrap justify="between" text="white" className="transition duration-300 ease-in-out border border-white border-1 mb-5 hover:bg-gray-300 hover:text-primary">
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text className="font-basic font-bold">{col.dates && col.dates}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.opening && col.opening}</Text>
                                        </Box>
                                        <Box className="p-3 lg:p-6 w-1/3" style={{ whiteSpace: 'pre-line' }}>
                                            <Text>{col.closing && col.closing}</Text>
                                        </Box>
                                    </Flex>   
                                </>    
                            ))}

                        </div>
                    </Box>
                ))}
            </div>
        </Box>
    )
}

export default SeasonalHours

